
import { createVNode } from "vue";
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  UploadOutlined,
  PlusOutlined
} from "@ant-design/icons-vue";
import Editor from "../editor/postage_editor.vue";
import Import from "../editor/postage_import.vue";
import { renderUnit } from "@/utils/common";

export default {
  components: {
    DeleteOutlined,
    PlusOutlined,
    UploadOutlined,
    Editor,
    Import
  },
  props: {
    data: Object
  },
  data() {
    return {
      url: "/admin/charge_group_postage/list",
      // 表格搜索条件
      where: {
        charge_group_id: this.data.id
      },
      // 表格选中数据
      selection: [],
      // 当前编辑数据
      current: null,
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示用户导入弹窗
      showImport: false,
      // 物流渠道列表
      ship_methods: []
    };
  },
  watch: {
    data() {
      if (this.data) this.where.charge_group_id = this.data.id;
      else this.where.charge_group_id = null;
      this.reload();
    }
  },
  computed: {
    columns() {
      return [
        {
          key: "index",
          width: 48,
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        {
          title: this.$t("渠道名称"),
          dataIndex: "ship_method_name",
          width: 150,
          sorter: true
        },
        {
          title: this.$t("邮编"),
          dataIndex: "postal_code",
          width: 150,
          sorter: true
        },
        {
          title: this.$t("分区"),
          dataIndex: "postal_code_zone",
          sorter: true
        },
        {
          title: this.$t("起始重量(Lbs)"),
          dataIndex: "begin_weight",
          width: 150,
          sorter: true,
          align: "center",
          customRender: ({ record }) => {
            return `${record.begin_weight} ${this.renderUnit(
              record,
              "weight_unit"
            )}`;
          }
        },
        {
          title: this.$t("结束重量(Lbs)"),
          dataIndex: "end_weight",
          width: 150,
          sorter: true,
          align: "center",
          customRender: ({ record }) => {
            return `${record.end_weight} ${this.renderUnit(
              record,
              "weight_unit"
            )}`;
          }
        },
        {
          title: this.$t("费用"),
          dataIndex: "value",
          width: 150,
          sorter: true,
          align: "center"
        },
        {
          title: this.$t("创建时间"),
          dataIndex: "created",
          width: 100,
          sorter: true
        },
        {
          title: this.$t("操作"),
          key: "action",
          width: 120,
          align: "center",
          slots: {
            customRender: "action"
          }
        }
      ];
    }
  },
  mounted() {
    this.load_ship_methods();
  },
  methods: {
    load_ship_methods() {
      this.$http
        .get("/admin/ship_method/simple_list")
        .then(res => {
          if (res.data.code === 0) {
            this.ship_methods = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(e => {
          this.$message.error(e.message);
        });
    },
    /* 搜索 */
    reload() {
      this.selection = [];
      this.$refs.table.reload({
        page: 1
      });
    },
    /*  重置搜索 */
    reset() {
      this.where = {};
      this.$nextTick(() => {
        this.reload();
      });
    },
    /**
     * 新建
     */
    createNew() {
      this.current = null;
      this.showEdit = true;
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row;
      this.showEdit = true;
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading("请求中...", 0);

      this.$http
        .post("/admin/charge_group_postage/delete", {
          ids: [row.id]
        })
        .then(res => {
          hide();
          if (res.data.code === 0) {
            this.$message.success(res.data.msg);
            this.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(e => {
          hide();
          this.$message.error(e.message);
        });
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        return this.$message.error("请至少选择一条数据");
      }

      this.$confirm({
        title: this.$t("提示"),
        content: this.$t("确定要删除选中的数据吗?"),
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading("请求中...", 0);

          this.$http
            .post("/admin/charge_group_postage/delete", {
              ids: this.selection.map(d => d.id)
            })
            .then(res => {
              hide();
              if (res.data.code === 0) {
                this.$message.success(res.data.msg);
                this.reload();
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch(e => {
              hide();
              this.$message.error(e.message);
            });
        }
      });
    },
    /* 清除邮资 */
    clearBatch() {
      this.$confirm({
        title: this.$t("提示"),
        content: this.$t("是否确认清空数据?"),
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading("请求中...", 0);

          this.$http
            .get(`/admin/charge_group_postage/clear/${this.data.id}`)
            .then(res => {
              hide();
              if (res.data.code === 0) {
                this.$message.success(res.data.msg);
                this.reload();
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch(e => {
              hide();
              this.$message.error(e.message);
            });
        }
      });
    }
  },
  setup() {
    return {
      renderUnit
    };
  }
};
